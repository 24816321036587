.nav-container {
  background-color: #00111c;
  /* border-bottom: 1px solid #006466; */
  height: 10vh;
  padding: 0 8%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;
}

.logo {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 1000;
  letter-spacing: -2px;
  padding: 6px 8px;
  border: 2px solid #006466;
  border-radius: 12px;
}

.menu-icon {
  color: #fff;
  padding: 0 0;
}
