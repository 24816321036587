@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;350;400;500;600;700;900;1000&display=swap);
/* CSS Reset */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
object,
h1,
h2,
h3,
p,
footer,
header,
menu,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
footer,
header,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}

/* Beginning of CSS */

body {
  font-family: "Roboto", sans-serif;
}

.App {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #00111c;
}

.profile {
  background: radial-gradient(#00111c, #002945);
  margin-top: -10vh;
  height: 100vh;
  padding: 0 20%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 1.8rem;
}

.profile-intro {
  font-size: 0.58em;
  font-weight: 400;
  color: #34a0a4;
}

.profile-name {
  padding: 4px 0;
  font-size: 1.5em;
  font-weight: 500;
}

.profile-caption {
  font-size: 0.7em;
  font-weight: 300;
  color: #898e9b;
  max-width: 400px;
}

.profile-links-container {
  background-color: #212f4560;
  padding: 6px 0;
  margin-top: 15px;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
}

.profile-link {
  height: 1em;
  padding: 0 5px;
  color: #fff;
}

.link {
  margin-bottom: -4px;
}

.section {
  height: 100vh;
  padding: 0 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 1.8rem;
}

.img {
  -webkit-align-self: center;
          align-self: center;
  height: 36vh;
  margin: 15px 10px;
  border-radius: 50%;
  border: 1px solid #000;
  -webkit-filter: drop-shadow(0 4px 10px #000);
          filter: drop-shadow(0 4px 10px #000);
}

section:nth-of-type(2n + 1) {
  background: radial-gradient(#002945, #00111c);
}

.footer {
  height: 25vh;
  background-color: #00111c;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.nav-container {
  background-color: #00111c;
  /* border-bottom: 1px solid #006466; */
  height: 10vh;
  padding: 0 8%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.logo {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 1000;
  letter-spacing: -2px;
  padding: 6px 8px;
  border: 2px solid #006466;
  border-radius: 12px;
}

.menu-icon {
  color: #fff;
  padding: 0 0;
}

