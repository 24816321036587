.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #00111c;
}

.profile {
  background: radial-gradient(#00111c, #002945);
  margin-top: -10vh;
  height: 100vh;
  padding: 0 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 1.8rem;
}

.profile-intro {
  font-size: 0.58em;
  font-weight: 400;
  color: #34a0a4;
}

.profile-name {
  padding: 4px 0;
  font-size: 1.5em;
  font-weight: 500;
}

.profile-caption {
  font-size: 0.7em;
  font-weight: 300;
  color: #898e9b;
  max-width: 400px;
}

.profile-links-container {
  background-color: #212f4560;
  padding: 6px 0;
  margin-top: 15px;
  border-radius: 10px;
  display: flex;
}

.profile-link {
  height: 1em;
  padding: 0 5px;
  color: #fff;
}

.link {
  margin-bottom: -4px;
}

.section {
  height: 100vh;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 1.8rem;
}

.img {
  align-self: center;
  height: 36vh;
  margin: 15px 10px;
  border-radius: 50%;
  border: 1px solid #000;
  filter: drop-shadow(0 4px 10px #000);
}

section:nth-of-type(2n + 1) {
  background: radial-gradient(#002945, #00111c);
}

.footer {
  height: 25vh;
  background-color: #00111c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
