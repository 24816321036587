@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;350;400;500;600;700;900;1000&display=swap");

/* CSS Reset */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
object,
h1,
h2,
h3,
p,
footer,
header,
menu,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
footer,
header,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}

/* Beginning of CSS */

body {
  font-family: "Roboto", sans-serif;
}
